export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5')
];

export const server_loads = [0];

export const dictionary = {
		"/[lang=lang]": [2],
		"/[lang=lang]/bookmarks": [3],
		"/[lang=lang]/notes": [4],
		"/[lang=lang]/notes/[slug]": [5]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';